<template>
  <div
    class="modal fade"
    :id="`exampleModal-` + modalId"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
    data-backdrop="false"
    v-if="dialog"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            {{ modalTitle }}
          </h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="form-group">
            <select class="form-control" v-model="form.status">
              <option value="">Select Status</option>
              <option
                :value="index"
                v-for="(s, index) in status"
                :key="index"
                >{{ s }}</option
              >
            </select>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">
            Close
          </button>
          <button
            type="button"
            class="btn btn-primary"
            @click="orderDepartmentUpdate"
          >
            Save changes
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { DepartmentOrderRepository } from "../../repositories/RepositoryFactory";
export default {
  props: ["modalTitle", "modalId", "modalStatus"],
  data() {
    return {
      form: {
        status: this.modalStatus,
      },
      id: this.modalId,
      status: [],
      dialog: true,
    };
  },
  mounted() {
    this.getStatus();
  },
  methods: {
    getStatus() {
      DepartmentOrderRepository.getStatus().then((result) => {
        this.status = result.data;
      });
    },
    orderDepartmentUpdate() {
      this.isSubmitting = true;

      let id = this.id;
      axios
        .post(
          this.$store.state.api + "order_department/update/" + id,
          this.form,
          {
            headers: {
              Authorization: this.$store.state.token,
            },
          }
        )
        .then(() => {
          this.$parent.$parent.$parent.$parent.getData();
          this.$noty.success("Your Order Department has been updated!");
        })
        .catch((error) => {
          this.errors = error.response.data;
          this.isSubmitting = false;
        });
    },
  },
};
</script>
